<template>
  <!-- eslint-disable max-len -->
  <div class='basic-details-main-wrapper'>
    <a-form>
      <a-row style='display: unset'>
        <a-col>
          <div class='basic-details-texts'>Your details</div>
          <div class='basic-details-name-email-wrapper'>
            <div style='width: 100%'>
              <a-form-item v-bind='validateInfos.email'>
                <a-input
                  style='width: 317px'
                  class='input-field'
                  v-model:value='modelRef.email'
                  placeholder='Enter Email'
                  :disabled='disable'
                >
                </a-input>
              </a-form-item>
            </div>
          </div>
          <div class='basic-details-continue-with-google-btn'>
            <div style='margin: 20px 0'>
              <a-divider
                style='
                  min-width: 300px;
                  max-width: 370px;
                  width: 370px;
                  margin: 0 auto;
                '
                >OR</a-divider
              >
            </div>
            <div>
              <GoogleLogin :callback="googleSignIn"/>
            </div>
          </div>
          <div class='basic-details-continue-btn'>
            <a-button type='primary' @click='onClickContinue' :loading="loading"
              >Continue</a-button
            >
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, reactive, ref } from 'vue';
// import { GoogleOutlined } from '@ant-design/icons-vue';
import { Form } from 'ant-design-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

const { useForm } = Form;

export default {
  components: {
    // GoogleOutlined,
  },
  setup() {
    const store = useStore();
    const disable = ref(false);
    const loading = ref(false);
    const modelRef = reactive({
      email: '',
    });
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          if (response.data.data.prefill.email) {
            modelRef.email = response.data.data.prefill.email;
          }
        })
        .catch(() => {
        });
    });

    const googleSignIn = (responseData) => {
      const data = {
        token: responseData.credential,
      };
      services
        .googleAuthentication(data)
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
        });
    };

    const router = useRouter();

    const rulesRef = reactive({
      email: [
        {
          required: true,
          message: 'Enter Email',
          type: 'email',
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );

    const onClickContinue = () => {
      // router.push('/email/otp');
      validate().then(() => {
        loading.value = true;
        disable.value = true;
        services
          .emailSignUp(modelRef)
          .then(() => {
            loading.value = false;
            store.dispatch('onboardingStore/updateEmail', modelRef.email);
            router.push('/onboarding/email/otp');
          })
          .catch((error) => {
            loading.value = false;
            disable.value = false;
            const systemError = Operations.axiosErrorHandler(error);
            const message = systemError.status;
            const description = systemError.message
              ? systemError.message
              : '';
            Operations.notificationError(message, description);
          });
      });
    };

    return {
      onClickContinue,
      validateInfos,
      resetFields,
      modelRef,
      googleSignIn,
      disable,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/basicInfo.scss';
</style>
